const androidProfile = 'talpa_chromecast_mp4_ovp_android';
const iosProfile = 'talpa_chromecast_mp4_ovp_ios';

const appPlayerChannel = 'kijk2 app';

export default function getAppConfiguration(
  adTagUrl: string = '',
): null | { playerChannel: string; platform: 'Android' | 'iOS' } {
  if (!adTagUrl) return null;

  if (adTagUrl.includes(androidProfile)) {
    return {
      playerChannel: appPlayerChannel,
      platform: 'Android',
    };
  }

  if (adTagUrl.includes(iosProfile)) {
    return {
      playerChannel: appPlayerChannel,
      platform: 'iOS',
    };
  }

  return null;
}
