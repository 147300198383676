import { CUSTOM_ERROR_CODES } from '../constants';
import { noticeError } from './newrelic';

function getErrorDetails(errorCode: number): { [key: string]: number | string | null } {
  return {
    error_code: errorCode,
    error_group:
      Object.keys(CUSTOM_ERROR_CODES).find(
        (key) => errorCode === CUSTOM_ERROR_CODES[key as keyof typeof CUSTOM_ERROR_CODES],
      ) || null,
  };
}

export default async function getVideo({ guid, videoApiURL }: { guid: string; videoApiURL: string }): Promise<{
  metadata: Metadata;
  source?: Source;
  tracks: Track[];
  drmToken: string;
} | null> {
  try {
    const query = encodeURIComponent(
      `{ programs(guid: "${guid}") { items { metadata, media { type } tracks { label kind file } sources { file type drm } } } drmToken { token } }`,
    );

    const response = await fetch(`${videoApiURL}/graphql?query=${query}`, {
      method: 'GET',
      headers: window.isProduction ? {} : { 'x-client-details': 'qa' },
    });
    const { data, errors } = await response.json();

    if (errors?.length) {
      const [error] = errors;
      noticeError('Failed to fetch program', {
        graphql_error: error.message,
        video_api_url: videoApiURL,
        graphql_query: query,
        ...getErrorDetails(CUSTOM_ERROR_CODES.PROGRAM_ERROR),
      });
      return null;
    }

    const { programs, drmToken } = data;

    const [video] = programs.items as { media: Media[]; metadata: Metadata; sources: Source[]; tracks: Track[] }[];
    if (!video) {
      noticeError('Video not found', getErrorDetails(CUSTOM_ERROR_CODES.PROGRAM_NOT_FOUND));
      return null;
    }

    const { media, metadata, sources, tracks } = video;

    // Fix for live streams
    if (media[0]?.type === 'LIVE') {
      metadata.c_media_playbacktype = 'live';
      metadata.media_duration = 0;
      metadata.c_media_live = true;
      metadata.media_videotype = 'live';
    }

    let source = sources.find(({ type, drm }) => type === 'dash' && (drm === null || !!drm?.widevine));

    if (!source) {
      source = sources.find(({ type }) => type === 'm3u8');
    }

    if (!source) {
      noticeError('No valid sources found', {
        ...getErrorDetails(CUSTOM_ERROR_CODES.PROGRAM_NO_SOURCES),
      });
    }

    return {
      metadata,
      source,
      tracks: tracks.filter((track) => track.kind !== 'thumbnails'),
      drmToken: drmToken.token,
    };
  } catch (error) {
    console.error(error);
    noticeError('Failed to fetch program', {
      graphql_error: (error as any)?.message,
      ...getErrorDetails(CUSTOM_ERROR_CODES.PROGRAM_UNKNOWN),
    });
  }
  return null;
}
