import { TEALIUM_CALL_TYPES } from '../constants';

declare global {
  interface Window {
    Taq: any;
  }
}

export function track(callType = TEALIUM_CALL_TYPES.LINK) {
  const Taq: any = (window as any).Taq || [];
  Taq.push(['track', callType]);
}

export function setDataLayer(data: { [key: string]: string }): void {
  window.Taq = window.Taq || [];
  window.Taq.push(['setDataLayer', data]);
}

export function trackView(): void {
  track(TEALIUM_CALL_TYPES.VIEW);
}
