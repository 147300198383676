import React from 'react';
import type { NextPage } from 'next';
import Head from 'next/head';
import Player from '../src/components/Player';
import { trackView } from '../src/utils';

const CastTag = 'cast-media-player' as keyof JSX.IntrinsicElements;

// prevent useEffect be called twice in strict mode
let mounted = false;

const Home: NextPage = () => {
  React.useEffect(() => {
    if (mounted) return;

    trackView();

    mounted = true;
    new Player();
  }, []);

  return (
    <div>
      <Head>
        <title>Kijk CAF receiver</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main>
        <CastTag id="player" />
      </main>
    </div>
  );
};

export default Home;
