declare global {
  interface Window {
    newrelic?: any;
  }
}

export let additionalAttributes: { [key: string]: string | boolean | null | undefined } = {};

export const setAdditionalAttributes = (data: { [key: string]: string | boolean | null | undefined }): void => {
  additionalAttributes = { ...additionalAttributes, ...data };

  if (typeof window.newrelic === 'object') {
    Object.keys(data).forEach((key) => {
      window.newrelic.setCustomAttribute(key, data[key]);
    });
  }
};

export function clearAdAttributes(): void {
  setAdditionalAttributes({ ad_id: null, creative_id: null, ad_content_url: null, vast_media_files: null });
}

export function noticeError(msg: string, customAttributes: { [key: string]: any } = {}): void {
  if (typeof window.newrelic !== 'object') return;

  window.newrelic.noticeError(new Error(msg), customAttributes);
}

export function customInteraction(msg: string): void {
  if (typeof window.newrelic !== 'object') return;

  window.newrelic.interaction().actionText(msg).save();
}
