import { XMLBuilder, XMLParser } from 'fast-xml-parser';
import Player from '../components/Player';

const options = {
  ignoreAttributes: false,
  attributeNamePrefix: '_',
};
const parser = new XMLParser(options);

const builder = new XMLBuilder(options);

export default function getCreatives(clips?: messages.BreakClip[]): Player['creatives'] {
  if (!clips?.length) return [];
  const creatives: Player['creatives'] = [];

  clips.forEach((clip) => {
    if (!clip.vastAdsRequest?.adsResponse) return;
    const {
      vastAdsRequest: { adsResponse },
      id: breakId,
    } = clip;
    try {
      const vastResponse = parser.parse(adsResponse);

      const ads: any[] = Array.isArray(vastResponse.VAST.Ad) ? vastResponse.VAST.Ad : [vastResponse.VAST.Ad];
      ads.forEach((ad) => {
        if (!ad?.InLine) return;

        const {
          InLine: { Creatives },
          _sequence,
        } = ad;

        const creative = Array.isArray(Creatives.Creative) ? Creatives.Creative[0] : Creatives.Creative;
        if (creative) {
          const { _id, _AdID, Linear } = creative;
          const { VideoClicks, MediaFiles } = Linear || {};
          creatives.push({
            breakId,
            sequence: _sequence,
            adId: _AdID,
            creativeId: _id,
            clickThroughUrl: VideoClicks?.ClickThrough?.['#text'],
            mediaFiles: Array.isArray(MediaFiles?.MediaFile)
              ? MediaFiles.MediaFile.map((mediaFile: any) => mediaFile?.['#text'])
              : [MediaFiles?.MediaFile?.['#text']],
            vastMediaFiles: builder.build(MediaFiles),
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  });
  return creatives;
}
