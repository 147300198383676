import { LOG_OUTPUT, LOG_LEVELS, NAMESPACE_TALPA } from '../../constants';

export default class Logger {
  castDebugLogger: debug.CastDebugLogger | null;
  logLevel = LOG_LEVELS.INFO;
  logOutput = LOG_OUTPUT.LOCAL;
  castCtx: framework.CastReceiverContext;

  constructor(castCtx: framework.CastReceiverContext) {
    this.castCtx = castCtx;
    this.castDebugLogger = typeof cast.debug !== 'undefined' ? cast.debug.CastDebugLogger.getInstance() : null;
  }

  sendLogToSender(method: 'debug' | 'info' | 'warn' | 'error', message: any): void {
    this.castCtx.sendCustomMessage(NAMESPACE_TALPA, undefined, JSON.stringify({ type: 'LOG', method, message }));
  }

  setup({ level, output }: { level?: number; output?: string }) {
    if (level) this.logLevel = level;
    if (output) this.logOutput = output;
    this.debug('[Logger] setup', { level: this.logLevel, output: this.logOutput });

    if (this.castDebugLogger) {
      // Todo: add core events when logLevel DEBUG is selected
      //   this.castDebugLogger.loggerLevelByEvents = {
      //     'cast.framework.events.category.CORE': cast.framework.LoggerLevel.INFO,
      //     'cast.framework.events.EventType.MEDIA_STATUS': cast.framework.LoggerLevel.DEBUG,
      //   };
      this.castDebugLogger.setEnabled(this.logLevel === LOG_LEVELS.DEBUG);
      this.castDebugLogger.showDebugLogs(this.logOutput === LOG_OUTPUT.OVERLAY);
      this.castDebugLogger.clearDebugLogs();
    }
  }

  log(method: 'debug' | 'info' | 'warn' | 'error', ...message: any): void {
    console[method](...message);

    if (this.logOutput === LOG_OUTPUT.BROADCAST) {
      this.sendLogToSender(method, message);
    }
    if (this.logOutput === LOG_OUTPUT.OVERLAY && this.castDebugLogger) {
      this.castDebugLogger[method]('CUSTOM_LOG', ...message);
    }
  }

  debug(...message: any) {
    if (this.logLevel === LOG_LEVELS.DEBUG) this.log('debug', ...message);
  }

  info(...message: any) {
    if (this.logLevel >= LOG_LEVELS.INFO) this.log('info', ...message);
  }

  warn(...message: any) {
    if (this.logLevel >= LOG_LEVELS.WARNING) this.log('error', ...message);
  }

  error(...message: any) {
    if (this.logLevel !== LOG_LEVELS.QUITE) this.log('error', ...message);
  }
}
