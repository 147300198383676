export default function setLicenseRequestBody(releasePid: string, requestInfo: { content: any }) {
  // convert widevine license payload from byte array to base64 string
  const licenseRequestBase64 = btoa(
    new Uint8Array(requestInfo.content).reduce((data, byte) => data + String.fromCharCode(byte), ''),
  );

  return new TextEncoder().encode(
    JSON.stringify({
      getRawWidevineLicense: {
        releasePid: releasePid,
        widevineChallenge: licenseRequestBase64,
      },
    }),
  );
}
