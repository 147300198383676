export const NAMESPACE_TWC = 'urn:x-cast:com.twcapps.ads.ima.cast';

export const NAMESPACE_TALPA = 'urn:x-cast:nl.talpavideo.caf';

export const ACTIONS = {
  SET_ACTIVE_TRACK: 'SET_ACTIVE_TRACK',
  TRACK_CHANGED: 'TRACK_CHANGED',
  ACTIVE_TRACK_SET: 'ACTIVE_TRACK_SET',
  TIME_UPDATE: 'TIME_UPDATE',
  BREAK_TIME_UPDATE: 'BREAK_TIME_UPDATE',
  ON_BREAK_CHANGED: 'ON_BREAK_CHANGED',
};

export const LOG_LEVELS = {
  QUITE: 0,
  DEBUG: 1,
  INFO: 2,
  WARNING: 3,
  ERROR: 4,
};

export const LOG_OUTPUT = {
  LOCAL: 'local',
  BROADCAST: 'broadcast',
  OVERLAY: 'overlay',
};

export const CUSTOM_ERROR_CODES = {
  PROGRAM_UNKNOWN: 10001,
  PROGRAM_NOT_FOUND: 10002,
  PROGRAM_ERROR: 10003,
  PROGRAM_NO_SOURCES: 10004,
};

export const DETAILED_ERROR_CODES = {
  100: 'MEDIA_UNKNOWN',
  101: 'MEDIA_ABORTED',
  102: 'MEDIA_DECODE',
  103: 'MEDIA_NETWORK',
  104: 'MEDIA_SRC_NOT_SUPPORTED',
  110: 'SOURCE_BUFFER_FAILURE',
  200: 'MEDIAKEYS_UNKNOWN',
  201: 'MEDIAKEYS_NETWORK',
  202: 'MEDIAKEYS_UNSUPPORTED',
  203: 'MEDIAKEYS_WEBCRYPTO',
  300: 'NETWORK_UNKNOWN',
  301: 'SEGMENT_NETWORK',
  311: 'HLS_NETWORK_MASTER_PLAYLIST',
  312: 'HLS_NETWORK_PLAYLIST',
  313: 'HLS_NETWORK_NO_KEY_RESPONSE',
  314: 'HLS_NETWORK_KEY_LOAD',
  315: 'HLS_NETWORK_INVALID_SEGMENT',
  316: 'HLS_SEGMENT_PARSING',
  321: 'DASH_NETWORK',
  322: 'DASH_NO_INIT',
  331: 'SMOOTH_NETWORK',
  332: 'SMOOTH_NO_MEDIA_DATA',
  400: 'MANIFEST_UNKNOWN',
  411: 'HLS_MANIFEST_MASTER',
  412: 'HLS_MANIFEST_PLAYLIST',
  420: 'DASH_MANIFEST_UNKNOWN',
  421: 'DASH_MANIFEST_NO_PERIODS',
  422: 'DASH_MANIFEST_NO_MIMETYPE',
  423: 'DASH_INVALID_SEGMENT_INFO',
  431: 'SMOOTH_MANIFEST',
  500: 'SEGMENT_UNKNOWN',
  600: 'TEXT_UNKNOWN',
  900: 'APP',
  901: 'BREAK_CLIP_LOADING_ERROR',
  902: 'BREAK_SEEK_INTERCEPTOR_ERROR',
  903: 'IMAGE_ERROR',
  904: 'LOAD_INTERRUPTED',
  905: 'LOAD_FAILED',
  906: 'MEDIA_ERROR_MESSAGE',
  999: 'GENERIC',
};

export const TEALIUM_CALL_TYPES = {
  VIEW: 'view',
  LINK: 'link',
  VIDEO: 'video',
};
